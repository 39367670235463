export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    borderRadius: '4px'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        
        MuiListItemButton: {
            styleOverrides: {
                '&:hover': {
                    // hover background color of navigation
                    backgroundColor: 'red',
                },
                root: {
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        // selected background color of navigation
                        backgroundColor: theme.menuSelectedBack,
                        
                        '&:hover': {
                            // hover background color of navigation
                            backgroundColor: theme.menuSelectedBack,
                        },
                        '& .MuiListItemIcon-root': {
                            // Icon color selected
                            color: theme.colors.darkPrimaryMain
                        },
                        '& .MuiListItemText-primary':{
                            //Text color
                            color: theme.menuSelected,
                            '&:hover': {
                                // hover background color of navigation
                                color: "black !important"
                            }
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        '& .MuiListItemIcon-root': {
                            // Icon color change on hover
                            color: theme.darkPaper
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                //Default icon color
                root: {
                    color: theme.darkPaper,
                    minWidth: '36px'
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    color: theme.whitePaper,
                    '&:hover':{
                        color: theme.darkPaper,
                        background: theme.darkLevel2
                    }
                }
            },
            
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.searchTextColor,
                    '&::placeholder': {
                        color: theme.searchTextColor,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.colors?.grey400
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: bgColor,
                    padding: '15.5px 14px',
                    borderRadius: `${theme?.customization?.borderRadius}px`,
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                notchedOutline: {
                    borderRadius: `${theme?.customization?.borderRadius}px`
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.whitePaper,
                    background: theme.colors?.darkPrimaryMain
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        },
        MuiChartsAxis: {
            styleOverrides: {
                directionY: {
                    label:{
                        display: 'none !important'
                    }
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child':{
                        paddingBottom: '12px'
                    }
                }
            }
        }
    };
}