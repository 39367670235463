import { BottomNavigation, BottomNavigationAction, Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const Footer = () => {

    const handleClick = () => {
        var mail = document.createElement("a");
        mail.href = "mailto:vishnuvardhanareddy.kareti@carrier.com; blanca.florentino@carrier.com";
        mail.click();
    }

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
            <Grid sx={{display: 'flex',justifyContent: 'center', marginLeft: '250px'}}>
                <BottomNavigation
                    showLabels
                >
                    <BottomNavigationAction LinkComponent={Link} onClick={handleClick}  label="Contact Us/Feedback" />
                    {/* <BottomNavigationAction label="Nearby" /> */}
                </BottomNavigation>
                <Typography align="right" sx={{fontSize: '12px',color: '#ccc',lineHeight: '54px', margin: '0 24px 0px auto'}}>Copyright &copy; 2024 Carrier. All rights reserved.</Typography>
            </Grid>
        </Paper>
    )
}