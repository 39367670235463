// export const umAppConfig = {
//     apiUrl: process.env.REACT_APP_AUTH_API_URL,
//     uiUrl: process.env.REACT_APP_UI_URL,
//     postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_URL,
// }

export const umAppConfig = {
    api: {
      apiUrl: "https://api.carrierappshub1.com/",
   
      uiUrl: "https://aiml-usage-stats.carrier.com",
   
      redirectAppUri: "https://auth.carrierappshub1.com/",
    },
};
   