import PropTypes from 'prop-types';

// material-ui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// project import
import { Divider } from '@mui/material';
import MainCard from './MainCard';

export default function UtilsCard({ title, packageName,cardDescription, count, icon, cardColor, chipColor, theme }) {
  return (
    <MainCard elevation={3} backgroundColor={cardColor}>
      {icon}
      <Stack spacing={0.2} sx={{mt:'10px'}}>
        <Typography variant="h4" color={theme.palette.common.white} textAlign="center">
          {title}
        </Typography>
        <Grid container alignItems="center" justifyContent="center">
            <Grid item sx={{mt:'5px'}}>
              <Chip
                variant="combined"
                label={count}
                sx={{ borderRadius:'5px', background: chipColor, color: theme.palette.common.white }}
                size="small"
              />
            </Grid>
        </Grid>
      </Stack>
      <Divider sx={{ pt: 2 }} />
      <Box sx={{ pt: 1 }}>
        <Typography variant="caption" color={theme.palette.common.white} textAlign="left">
          {cardDescription}
        </Typography>
      </Box>
    </MainCard>
  );
}

UtilsCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  cardDescription: PropTypes.string,
  packageName: PropTypes.string,
  count: PropTypes.number,
  icon: PropTypes.element,
  cardColor: PropTypes.string,
  chipColor: PropTypes.string
};