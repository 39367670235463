import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { PageHeading } from '../../Components/Common/PageHeading';
import Readme from './Readme';
import { Box, TextField } from '@mui/material';
import { ApiService } from '../../utils/ApiService';
import StyledDataGrid from '../../Components/StyledGrid';
// Styled components similar to the table example

export const Libraries = () => {
  const [libraryDetails, setLibraryDetails] = useState({ open: false, name: null });
  const [searchTerm, setSearchTerm] = useState('');


  const handleClickOpen = (name) => {
    setLibraryDetails({ ...libraryDetails, name, open: true });
  };

  const handleClose = () => {
    setLibraryDetails({ ...libraryDetails, open: false });
  };

  const [librariesInfo, setLibrariesInfo] = useState({ data: [], isLoading: true, error: null });

  useEffect(() => {
    setLibrariesInfo({ ...librariesInfo, isLoading: true });
    ApiService('libraries', 'GET')
      .then((response) => {
        setLibrariesInfo({ ...librariesInfo, data: response.result, isLoading: false });
      })
      .catch((error) => setLibrariesInfo({ ...librariesInfo, isLoading: false, error }));
  }, []);

  const columns = [
    { field: 'col1', headerName: 'Capability Type', width: 160 },
    { field: 'col2', headerName: 'Capability Name', flex: 0.7 },
    {
      field: 'col3',
      headerName: 'Module',
      width: 200,
      renderCell: (cellValues) => (
        <Link
          style={{ color: '#1e88e5', textDecoration: 'none' }}
          onClick={() => handleClickOpen(cellValues?.row?.col3)}
        >
          {cellValues?.row?.col3}
        </Link>
      ),
    },
    { field: 'col4', headerName: 'Description', flex: 1 },
    { field: 'col5', headerName: 'Repo', flex: 1 },
  ];

  const rows = useMemo(() => {
    if (!librariesInfo.data || librariesInfo.data.length === 0) return [];

    const searchValue = searchTerm.toLowerCase();

    return librariesInfo.data
      .filter(library =>
        library.capabilityName.toLowerCase().includes(searchValue) ||
        library.category.toLowerCase().includes(searchValue) ||
        library.packageName.toLowerCase().includes(searchValue)
      )
      .map((library, i) => ({
        id: i + 1,
        col1: library.category,
        col2: library.capabilityName,
        col3: library.packageName,
        col4: library.description,
        col5: library.repo,
      }));
  }, [librariesInfo, searchTerm]);

  return (
    <Grid style={{ marginBottom: "2.2rem" }} container spacing={2}>
      {libraryDetails.open && <Readme libraryDetails={libraryDetails} handleClose={handleClose} />}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" style={{ marginBottom: '10px' }}>
          <PageHeading color="black" variant="h3" textAlign="left" name="Libraries" />
          <TextField
            variant="outlined"
            placeholder="Search..."
            size="small"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            style={{ width: "20rem", marginLeft: '20px' }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <StyledDataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              columns: {
                columnVisibilityModel: {
                  col5: false, // Hide Repo Column initially
                },
              },
            }}
            pageSizeOptions={[5, 10, 25, 50]}
            autoHeight
            disableRowSelectionOnClick
            loading={librariesInfo.isLoading}
            components={{
              LoadingOverlay: () => (
                <div style={{ padding: '20px', textAlign: 'center' }}>
                  <CircularProgress />
                </div>
              ),
              Toolbar: GridToolbar,
            }}
            localeText={{
              noRowsLabel: 'No Libraries found',
            }}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Libraries;
