import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Link } from 'react-router-dom';
import { DialogContent, DialogContentText, DialogTitle, Skeleton, useTheme } from '@mui/material';
import { marked } from "marked";
import Markdown from 'markdown-to-jsx';
import { ApiService } from '../../utils/ApiService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Readme({ libraryDetails, handleClose }) {

    const theme = useTheme();
    const [data, setData] = React.useState({isLoading: false, result: null });
    const [librariesInfo, setLibrariesInfo] = React.useState({ data:[], isLoading: true, error: null });
    // React.useEffect(() => {
    //     setData({...data, isLoading: true})
    //     import(`./README.md`)
    //         .then(res => {
    //             fetch(res.default)
    //                 .then(res => res.text())
    //                 .then(res => {
    //                     setData({...data, result: res, isLoading: false})
    //                 })
    //                 .catch(err => console.log(err));
    //         })
    //         .catch(err => console.log(err));
    // },[]);

    // console.log(data,"data..........")

    React.useEffect( () => {
      setLibrariesInfo({...librariesInfo, isLoading: true})
      ApiService(`readme?module_name=${libraryDetails.name}&file_name=README.md`,'GET', '')
          .then(response => {
              console.log(response,"This one is the response..........")
              setLibrariesInfo({...librariesInfo, data:response.result, isLoading: false})
          })
          .catch(error => setLibrariesInfo({...librariesInfo, isLoading: false, error}))
  },[]);

      // React.useEffect(() => {
      //   setData({...data, isLoading: true})
      //   fetch('./README.md')
      //     .then((response) => {
      //       return response.text();
      //     })
      //     .then((text) => {
      //       setData({...data, result: text, isLoading: false})
      //     });
      // }, []);

    const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (libraryDetails.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [libraryDetails.open]);

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={libraryDetails.open}
                onClose={handleClose}
                TransitionComponent={Transition}
                className='readme'
                sx={{backgroundColor: '#fff !important'}}
            >
                <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: theme.palette.primary.main, lineHeight: 2.5}} variant="h1" component="div">
              {libraryDetails.name}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={handleClose}>
              save
            </Button> */}
          </Toolbar>
                <DialogContent dividers={'paper'} style={{padding: '0px 59px', borderTop: 'none'}}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {!librariesInfo.isLoading ? <Markdown>
                            {librariesInfo.data}
                        </Markdown>: <>
                    {Array(20).fill(0).map((d, i) => {
                    let style={width: '98%', height:'25px'}
                    if(i === 0 || i === 2 || i === 5 || i === 8 ){
                        style={width: '40%', height: '40px'}
                    }else if( i === 1 || i === 9  ){
                        style={width: '60%', height: '30px'}
                    }else if(i === 3 ){
                        style={width: '30%', height: '35px'}
                    }
                    return (
                        <>
                            <Skeleton key={`${d}-${i}`} elevation={5} sx={{ borderRadius: "0px", marginBottom: '15px', ...style }} animation="wave" variant="rectangular" />
                        </>
                    )})}
                </>}
                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </React.Fragment>
    );
}