import { styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: "#fff",
    color: "black",
    fontSize: 17,
    fontWeight: "bold"
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.text.primary,
    fontSize: 14.5,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    lineHeight: '1.1',
    paddingTop: "10px",
    overflow: 'visible', // Make sure content is not hidden
    display: 'inline-block', // Allow cell to grow with content
  },
  '& .MuiDataGrid-row:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '& .MuiDataGrid-row:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& .MuiDataGrid-footerContainer': {
    backgroundColor: "#ffffff",
    color: theme.palette.common.white,
  },
}));

export default StyledDataGrid;
