import React, { useState } from 'react';
import Plot from 'react-plotly.js';

const CombinedChartdata = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return <div>No data available</div>;
  }

  const dates = Object.keys(data)
    .sort((a, b) => new Date(a) - new Date(b))
    .map(date => date.toString());

  const categories = Object.keys(data[dates[0]]);

  const traces = categories.map((category, index) => {
    const userUsageCounts = dates.map((date) => data[date][category]?.userUsagecount || 0);
    const cloudUsageCounts = dates.map((date) => data[date][category]?.cloudUsagecount || 0);

    return [
      {
        x: dates,
        y: userUsageCounts,
        type: 'scatter',
        mode: 'lines+markers',
        name: `${category} User Usage Count`,
        line: { color: 'navy', shape: 'spline', width: 2 },
        marker: { color: 'navy', symbol: 'circle', size: 6 },
        hoverinfo: 'x+y+name',
        visible : 'legendonly'
      },
      {
        x: dates,
        y: cloudUsageCounts,
        type: 'scatter',
        mode: 'lines+markers',
        name: `${category} Cloud Usage Count`,
        line: { color: 'teal', shape: 'spline', width: 2 },
        marker: { color: 'teal', symbol: 'square', size: 6 },
        hoverinfo: 'x+y+name',
        visible: category === "ML algorithms" ? true : 'legendonly'
      }
    ];
  }).flat();

  const layout = {
    title: `Usage Trends by Category`,
    xaxis: { title: '', tickangle: -45, type: 'category' },
    yaxis: { title: 'Usage Counts', gridcolor: '#e5e5e5' },
    hovermode: 'x unified',
    showlegend: true,
    legend: {
      itemclick: 'toggle',
      orientation: 'h',
      y: -0.2
    },
    margin: { t: 40, b: 100, l: 60, r: 20 }
  };

  return (
    <Plot
      data={traces}
      layout={layout}
      style={{ width: '100%', height: '100%' }}
      useResizeHandler={false}
    />
  );
};

export default CombinedChartdata;
