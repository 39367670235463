import { AppBar, Box, Container, Stack, Toolbar, Typography, useTheme, Grid } from "@mui/material"
import { ProfileMenu } from "../ProfileMenu"

const Header = (props) => {

    const { drawerWidth } = props;
    const theme = useTheme();
    return (
        <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px`, background: theme.palette.common.white }} elevation={1}>
            <Container sx={{height: '55px'}} maxWidth="xl">
                <Toolbar variant="regular" disableGutters>
                    <Grid container>
                        <Grid xs={10}>
                            <Typography variant="h4" sx={{color: theme.palette.primary.main, lineHeight: 2.5}}>AIML Platform  Reusable Libraries</Typography>
                        </Grid>
                    {/* <Typography variant="h4" textAlign="center">AIML Platform  Reusable Libraries</Typography> */}
                    {/* <Divider orientation="vertical" variant='middle' flexItem sx={{backgroundColor: '#ccc', marginLeft: '10px', marginRight: '10px'}} /> */}
                    {/* <Navigation /> */}  
                        
                        <Grid xs={2}>
                            <ProfileMenu />
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default Header;