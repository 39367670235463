export const getHeaders = () => {
    const token = localStorage.getItem('msal.idtoken');
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("x-api-key", process.env.REACT_APP_X_API_KEY);
    return myHeaders
}

export const ApiService = (endPoint, method = 'GET', payload = '', resType=null ) => {
    console.log(resType, "Res type..........")
    let requestOptions = {
        method,
        headers: getHeaders(),
    };
    if( payload ){
        requestOptions = {...requestOptions, body: JSON.stringify(payload)}
    }
    if( resType !== null ){
        return fetch(`${process.env.REACT_APP_API_URL}/${endPoint}`, requestOptions).then(res => res.text());
    }else{
        return fetch(`${process.env.REACT_APP_API_URL}/${endPoint}`, requestOptions).then(res => res.json());
    }
}