import { ReactAuthWrapper } from "@carrier/reactauthwrapper";
import './App.css';
import { umAppConfig } from "./appConfig";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material";
import { Themed } from "./theme";
import { Routing } from "./routing";
import UserContext from "./contexts/UserContext";
import { ApiService } from "./utils/ApiService";
import { GetUserProfileAndPermissions } from '@carrier/auth-module';
import { GetGroupDetailsById } from '@carrier/auth-module';




function App() {
  const [userInfo, setUsersInfo] = useState({ data: {}, isLoading: true, error: null });

  useEffect(() => {
    setUsersInfo({ ...userInfo, isLoading: true })
    ApiService('user-by-token', 'GET')
      .then(response => {
        setUsersInfo({ ...userInfo, data: response.result, isLoading: false })
      })
      .catch(error => setUsersInfo({ ...userInfo, isLoading: false, error }))
  }, []);


  return (
    <div className="App">
      <ReactAuthWrapper appConfig={umAppConfig}>
        <UserContext.Provider value={userInfo.data}>
          <ThemeProvider theme={Themed}>
            <Routing />
          </ThemeProvider>
        </UserContext.Provider>
      </ReactAuthWrapper>
    </div>
  );
}

export default App;
