import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, useTheme } from "@mui/material"
import DashboardIcon from '@mui/icons-material/Dashboard';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Link, useLocation } from "react-router-dom"
import Logo from "../../assets/carrier-logo.png";
import Divider from '@mui/material/Divider';
import { SideNavListItem } from "./SideNavListItem";
const navMenus = [
    {name: 'Dashboard', to: '/', icon: <DashboardIcon />},
    {name: 'Libraries', to: '/libraries', icon: <LibraryBooksIcon />},
    {name: 'Usage Stats', to: '/usage-stats', icon: <TableChartIcon />}
];

export const Sidebar = (props) => {

    const {drawerWidth}= props;
    const {pathname} = useLocation();
    const theme = useTheme();
    return (
        <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: theme.palette.primary.main,
            border: 'none'
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar sx={{background: theme.palette.common.white}}>
            <Link to={process.env.REACT_APP_UI_URL || '/'} style={{margin: 'auto'}}>
                <Box
                    component="img"
                    sx={{
                        width: 100,
                    }}
                    title="AIML Platform - Reusable Libraries"
                    src={Logo}
                />
            </Link>
        </Toolbar>
        <Divider />
        <List>
          {navMenus.map(navMenu => (
            <SideNavListItem to={navMenu.to} key={navMenu.to} selected={pathname === navMenu.to} disablePadding={true} navMenu={navMenu} />
          ))}
        </List>
      </Drawer>
    )
}